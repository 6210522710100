import { useState, useRef, useEffect } from 'react'
import props from 'prop-types'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { useGSAP } from '@gsap/react'
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
gsap.registerPlugin(useGSAP, ScrollTrigger)

import './FeaturesSection.scss'
import { Linear } from 'gsap/gsap-core'
import arrow from '@/assets/images/vector/arrow-long.svg'
import { BrowserView, MobileView, isMobile, isDesktop } from 'react-device-detect'

//const images = window.imagesCanvasArray
export default function FeaturesSection() {
	const featSect = useRef()
	const swiperElem = useRef()


	useEffect(() => {

		const swiper = new Swiper(swiperElem.current, {
			modules: [Navigation, Pagination],
			loop: false,
			pagination: {
				el: '.features-section  .swiper-pagination',
			},
			// Navigation arrows
			navigation: {
				nextEl: '.features-section .swiper-button-next',
				prevEl: '.features-section .swiper-button-prev',
			},
		});
	}, [])

	useGSAP(
		() => {
			if (isMobile) {

			}
			if (isDesktop) {
				gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: 'top bottom',
						end: () => 'bottom top',
						pin: '.section-fixed-box',
						scrub: true,
						invalidateOnRefresh: true
					}
				})

				let thisTL = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: 'top bottom',
						end: () => '+=' + window.innerHeight,
						scrub: true,
						invalidateOnRefresh: true
					}
				})
				thisTL.fromTo('.features-heading-box', { yPercent: 70, opacity: 1 }, { yPercent: 0, ease: Linear.easeNone }, 0)



				let thisTLPic = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: 'top bottom',
						end: 'bottom bottom',
						scrub: true,
						invalidateOnRefresh: true
					}
				})
				thisTLPic.fromTo('.feats-bg', { xPercent: 0 }, { xPercent: -10, ease: Linear.easeNone }, 0)
				thisTLPic.fromTo('.progress-fill', { height: 0 }, { height: '100%', ease: Linear.easeNone }, 0)
				//feat01 In
				let thisTLFeat1In = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: 'top center',
						end: () => '+=' + 300,
						scrub: true,
						invalidateOnRefresh: true
					}
				})
				thisTLFeat1In.fromTo('.section-main-button ', { y: 50, opacity: 0 }, { y: 0, opacity: 1, ease: Linear.easeNone }, 0)
				thisTLFeat1In.fromTo('.feat01 .feat-content', { opacity: 0 }, { opacity: 1, ease: Linear.easeNone }, 0)
				let thisTLFeat1InBG = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: 'top center',
						end: () => '+=' + 1000,
						scrub: true,
						invalidateOnRefresh: true
					}
				})

				thisTLFeat1InBG.fromTo('.feat01 .feat-bg', { x: 50 }, { x: -50, ease: Linear.easeNone }, 0)


				//feat01 Out
				let thisTLFeat1Out = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: () => '+=' + 200,
						end: () => '+=' + 200,
						scrub: true,
						invalidateOnRefresh: true
					}
				})
				thisTLFeat1Out.fromTo('.feat01 .content-box', { opacity: 1 }, { opacity: 0, ease: Linear.easeNone }, 0)
				thisTLFeat1Out.fromTo('.feat01 .feat-bg', { scale: 1, opacity: 1 }, { scale: 1, opacity: 0, ease: Linear.easeNone }, 0)

				//feat02 in
				let thisTLFeat2In = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: () => '+=' + 400,
						end: () => '+=' + 200,
						scrub: true,
						invalidateOnRefresh: true
					}
				})
				thisTLFeat2In.fromTo('.feat02 .feat-bg', { opacity: 0 }, { opacity: 1, ease: Linear.easeNone }, 0)
				thisTLFeat2In.fromTo('.feat02 .feat-content', { opacity: 0 }, { opacity: 1, ease: Linear.easeNone }, 0)
				let thisTLFeat2InBG = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: () => '+=' + 400,
						end: () => '+=' + 1000,
						scrub: true,
						invalidateOnRefresh: true
					}
				})


				thisTLFeat2InBG.fromTo('.feat02 .feat-bg', { x: 50 }, { x: -50, ease: Linear.easeNone }, 0)
				//feat02 Out
				let thisTLFeat2Out = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: () => '+=' + 1200,
						end: () => '+=' + 200,
						scrub: true,
						invalidateOnRefresh: true
					}
				})
				thisTLFeat2Out.fromTo('.feat02 .feat-content .content-box', { opacity: 1 }, { opacity: 0, ease: Linear.easeNone }, 0)
				thisTLFeat2Out.fromTo('.feat02 .feat-bg picture', { opacity: 1 }, { opacity: 0, ease: Linear.easeNone }, 0)

				//feat03 in
				let thisTLFeat3InBG = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: () => '+=' + 1400,
						end: () => '+=' + 1000,
						scrub: true,
						invalidateOnRefresh: true
					}
				})


				thisTLFeat3InBG.fromTo('.feat03 .feat-bg', { x: 50 }, { x: -50, ease: Linear.easeNone }, 0)

				let thisTLFeat3In = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: () => '+=' + 1400,
						end: () => '+=' + 200,
						scrub: true,
						invalidateOnRefresh: true
					}
				})
				thisTLFeat3In.fromTo('.feat03 .feat-bg', { opacity: 0 }, { opacity: 1, ease: Linear.easeNone }, 0)
				thisTLFeat3In.fromTo('.feat03 .feat-content', { opacity: 0 }, { opacity: 1, ease: Linear.easeNone }, 0)

				let thisTLFeat3Out = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: () => '+=' + 2200,
						end: () => '+=' + 200,
						scrub: true,
						invalidateOnRefresh: true
					}
				})
				thisTLFeat3Out.fromTo('.feat03 .feat-content .content-box', { opacity: 1 }, { opacity: 0, ease: Linear.easeNone }, 0)
				thisTLFeat3Out.fromTo('.feat03 .feat-bg picture', { opacity: 1 }, { opacity: 0, ease: Linear.easeNone }, 0)

				//feat04 in
				let thisTLFeat4InBG = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: () => '+=' + 2400,
						end: () => '+=' + 1000,
						scrub: true,
						invalidateOnRefresh: true
					}
				})


				thisTLFeat4InBG.fromTo('.feat04 .feat-bg', { x: 50 }, { x: -50, ease: Linear.easeNone }, 0)

				let thisTLFeat4In = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: () => '+=' + 2400,
						end: () => '+=' + 200,
						scrub: true,
						invalidateOnRefresh: true
					}
				})
				thisTLFeat4In.fromTo('.feat04 .feat-bg', { opacity: 0 }, { opacity: 1, ease: Linear.easeNone }, 0)
				thisTLFeat4In.fromTo('.feat04 .feat-content', { opacity: 0 }, { opacity: 1, ease: Linear.easeNone }, 0)

				let thisTLFeat4Out = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: () => '+=' + 3200,
						end: () => '+=' + 200,
						scrub: true,
						invalidateOnRefresh: true
					}
				})
				thisTLFeat4Out.fromTo('.feat04 .feat-content .content-box', { opacity: 1 }, { opacity: 0, ease: Linear.easeNone }, 0)
				thisTLFeat4Out.fromTo('.feat04 .feat-bg picture', { opacity: 1 }, { opacity: 0, ease: Linear.easeNone }, 0)

				//feat05 in
				let thisTLFeat5InBG = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: () => '+=' + 3400,
						end: () => '+=' + 900,
						scrub: true,
						invalidateOnRefresh: true
					}
				})

				thisTLFeat5InBG.fromTo('.feat05 .feat-bg', { x: 50 }, { x: -50, ease: Linear.easeNone }, 0)

				let thisTLFeat5In = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.features-fix-wrap',
						start: () => '+=' + 3400,
						end: () => '+=' + 200,
						scrub: true,
						invalidateOnRefresh: true
					}
				})
				thisTLFeat5In.fromTo('.feat05 .feat-bg', { opacity: 0 }, { opacity: 1, ease: Linear.easeNone }, 0)
				thisTLFeat5In.fromTo('.feat05 .feat-content', { opacity: 0 }, { opacity: 1, ease: Linear.easeNone }, 0)



				let thisTLFeatOut = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: featSect.current,
						start: () => 'bottom bottom',
						end: () => 'bottom top',
						scrub: true,
						invalidateOnRefresh: true
					}
				})
				thisTLFeatOut.fromTo('.swiper', { opacity: 1, y:0 }, { opacity: 0, y:-100, ease: Linear.easeNone }, 0)
				thisTLFeatOut.fromTo('.feats-bg picture', { opacity: 1 }, { opacity: 0, ease: Linear.easeNone }, 0)
				thisTLFeatOut.fromTo('.features-heading-box .container', { yPercent:0, opacity:1}, {opacity:0, yPercent: -70, ease: Linear.easeNone }, 0)
			}
		},
		{ scope: featSect }
	) // <-- scope for selector text (optional)
	return (
		<>
			<div className="features-section" ref={featSect}>
				<div className="features-fix-wrap">
					<div className="section-fixed-box">
						<div className="features-heading-box">
							<div className="container">
								<h2>
									<span>AN <b>UNRIVALED</b><br /></span>{' '}
									<b>TRADING EXPERIENCE</b>
								</h2>
							</div>
						</div>
						{!isMobile && (
							<>

								<div className="section-main-button ">
									<div className="feat-container">
										<div className="right-btn">
											<a href="#" className="launch-btn">
												<span>launch app</span>
												<i>
													<img src={arrow} alt="" />
												</i>
											</a>
										</div>
									</div>
								</div>

								<div className="section-progress">
									<div className="progressbar">
										<span className="progress-fill"></span>
									</div>
								</div>
							</>
						)}
						<div className="swiper" ref={swiperElem}>
							<div className="feats-boxes swiper-wrapper">
								<div className="feat-item swiper-slide feat01">
									<div className="feat-bg sequence">
										<picture>
											<source srcSet="images/feat01.webp" media="(min-width: 768px)" />
											<img srcSet="images/feat01.webp" alt="" className="img-responsive" />
										</picture>
									</div>
									<div className="feat-content">
										<div className="content-box feat-container">
											<h3>
												Advanced <br />
												Trading Tools
											</h3>
											<div className="textbox">
												<p>
													Enhance your trading experience with a suite of tools designed for precision and efficiency. Includes various order types,
													automated sniping, and strategic buy systems.
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="feat-item swiper-slide feat02">
									<div className="feat-bg">
										<picture>
											<source srcSet="images/feat02.webp" media="(min-width: 768px)" />
											<img srcSet="images/feat02.webp" alt="" className="img-responsive" />
										</picture>
									</div>
									<div className="feat-content">
										<div className="content-box feat-container">
											<h3>
												In-Depth
												<br />
												Analytics
											</h3>
											<div className="textbox">
												<p>
													Empower your trading decisions with our analytics. Features real-time token charts, performance tracking, and a
													comprehensive position management.
												</p>
											</div>
										</div>
									</div>
								</div>
								<div className="feat-item swiper-slide feat03">
									<div className="feat-bg">
										<picture>
											<source srcSet="images/feat03.webp" media="(min-width: 768px)" />
											<img srcSet="images/feat03.webp" alt="" className="img-responsive" />
										</picture>
									</div>
									<div className="feat-content">
										<div className="content-box feat-container">
											<h3>
												Risk <br />
												Management
											</h3>
											<div className="textbox">
												<p>Utilize advanced tools to manage and mitigate risks, ensuring a balanced and secure trading strategy.</p>
											</div>
										</div>
									</div>
								</div>
								<div className="feat-item swiper-slide feat04">
									<div className="feat-bg">
										<picture>
											<source srcSet="images/feat04.webp" media="(min-width: 768px)" />
											<img srcSet="images/feat04.webp" alt="" className="img-responsive" />
										</picture>
									</div>
									<div className="feat-content">
										<div className="content-box feat-container">
											<h3>
												Automated <br />
												Trading
											</h3>
											<div className="textbox">
												<p>Customize and deploy automated positions to execute strategies based on specific token parameters.</p>
											</div>
										</div>
									</div>
								</div>
								<div className="feat-item swiper-slide feat05">
									<div className="feat-bg">
										<picture>
											<source srcSet="images/feat05.webp" media="(min-width: 768px)" />
											<img srcSet="images/feat05.webp" alt="" className="img-responsive" />
										</picture>
									</div>
									<div className="feat-content">
										<div className="content-box feat-container">
											<h3>
												Multi-Chain <br />
												Trading
											</h3>
											<div className="textbox">
												<p>Trade seamlessly across different blockchain networks, leveraging multi-chain support to expand your trading horizons.</p>
											</div>
										</div>
										tr
									</div>
								</div>
							</div>
							<div className="swiper-pagination"></div>
							<div className="swiper-button-prev"></div>
							<div className="swiper-button-next"></div>
						</div>

						<div className="feats-bg">
							<picture>
								<source srcSet="images/feats-bg.webp" media="(min-width: 768px)" />
								<img srcSet="images/feats-bg-xs.webp" alt="" className="img-responsive" />
							</picture>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
