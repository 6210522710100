import { useState, useRef, useEffect } from 'react'
import props from 'prop-types'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin(useGSAP, ScrollTrigger)

import './LevelsSection.scss'
import { Linear } from 'gsap/gsap-core'
import yes from '@/assets/images/vector/galka-good.svg'
import no from '@/assets/images/vector/galka-bad.svg'
import arrow from '@/assets/images/vector/arrow-long.svg'
import Swiper from 'swiper'
import { Navigation, Pagination } from 'swiper/modules'
import { isMobile } from 'react-device-detect'

//const images = window.imagesCanvasArray
export default function LevelsSection() {
	const levelsSect = useRef()
	const canvasElem = useRef()
	const sequenceElem = useRef()
	const swiperElem = useRef()
	const sequenceFrames = {
		frame: 1,
	}
	let currentImagesLoaded = 0
	let picturesLoaded = false
	const startFrame = 1
	const endFrame = 60
	const framesPath =  'images/sequences/level-enterprise/'
	const frameCount = endFrame - startFrame
	let imagesArray = []
	let canvas
	let context
	let picturesToLoadCount = endFrame+1
	let thisTLRender
	function render() {
		context.clearRect(0, 0, canvas.width, canvas.height)
		context.drawImage(imagesArray[sequenceFrames.frame], 0, 0)
	}

	useEffect(() => {
		canvas = canvasElem.current
		context = canvasElem.current.getContext("2d")
		canvas.width = 476
		canvas.height = 464
		let imagesLoadedCount = 0
		for (let i = startFrame; i <= endFrame; i++) {
			const img = new Image()
			img.src = framesPath + i.toString().padStart(4, "0") + '.webp'
			imagesArray.push(img)
		}
		imagesArray.forEach( img => {
			img.onload = function() {
				++imagesLoadedCount
				if (endFrame === imagesLoadedCount) {
					console.log(`enterprise loaded`)
					sequenceElem.current.classList.add('loaded')
					thisTLRender.play()
				}
			}
		});
		if(isMobile){
			const swiper = new Swiper(swiperElem.current, {
				modules: [Navigation, Pagination],
				loop: false,
				slidesPerView: 'auto',
				centeredSlides: true,
				initialSlide: 1,
				pagination: {
					el: '.levels-section  .swiper-pagination',
				},

			});
		}

	}, [])

	useGSAP(() => {
		gsap.timeline({
			paused: true,
			scrollTrigger: {
				trigger: levelsSect.current,
				start: 'top bottom',
				end: () => 'bottom top',
				pin: '.sect-bg',
				scrub: true,
				invalidateOnRefresh: true,
			}
		})
		let thisTLOut = gsap.timeline({
			scrollTrigger: {
				trigger: levelsSect.current,
				start: "top bottom",
				end: "bottom top",
				scrub: true,
				invalidateOnRefresh: true,
			},
		})

		thisTLOut.fromTo(
			'.sect-bg',
			{ yPercent:0 },
			{ yPercent:-60, ease: Linear.easeNone },
			0,
		)

		thisTLRender = gsap.timeline({
			paused: true,
			repeat: -1,
			onUpdate: render,
		})
		thisTLRender.fromTo(
			sequenceFrames,
			{ frame: () => 1 },
			{ frame: () => frameCount, snap: "frame", duration:2, ease: Linear.easeNone },
			0,
		)

	}, { scope: levelsSect }) // <-- scope for selector text (optional)
	return (
		<>
			<div className="levels-section" ref={levelsSect}>
				<div className="sect-content">
					<div className="heading-box">

						<div className="container">
							<h2>
								<span>
									<b>/</b>UPGRADE
								</span>
								<span>YOUR TIER</span>
							</h2>
						</div>
					</div>
					<div className="short-container">
						<div className="swiper" ref={swiperElem}>
							<div className="pricing-table swiper-wrapper">
								<div className="table-item swiper-slide">
									<div className="item-head">
										<div className="top-row">

										</div>
										<span className="item-name">BASE</span>
										<div className="item-pic">
											<picture>
												<source srcSet="images/levels-base.webp" media="(min-width: 768px)" />
												<img srcSet="images/levels-base.webp" alt="" className="img-responsive" />
											</picture>
										</div>
									</div>

									<ul className="item-feats">
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>10 wallets</span>
										</li>
										<li>
											<i>
												<img src={no} alt="" />
											</i>
											<span>Reduced fees</span>
										</li>
										<li>
											<i>
												<img src={no} alt="" />
											</i>
											<span>ASAS Mode</span>
										</li>
										<li>
											<i>
												<img src={no} alt="" />
											</i>
											<span>SBOS Mode</span>
										</li>
										<li>
											<i>
												<img src={no} alt="" />
											</i>
											<span>Advantage System Access</span>
										</li>
										<li>
											<i>
												<img src={no} alt="" />
											</i>
											<span>Chost Router Access</span>
										</li>
										<li>
											<i>
												<img src={no} alt="" />
											</i>
											<span>API Access</span>
										</li>
										<li>
											<i>
												<img src={no} alt="" />
											</i>
											<span>Custom Batch Order Execution</span>
										</li>
										<li>
											<i>
												<img src={no} alt="" />
											</i>
											<span>Unique Trade Data inflow</span>
										</li>
									</ul>
									<div className="bottom-box">
										<button type="button">
											<span>get started</span>
											<i>
												<img src={arrow} alt="" />
											</i>
										</button>
									</div>
								</div>
								<div className="table-item swiper-slide pro">
									<div className="item-head">
										<div className="top-row">

										</div>
										<span className="item-name">pro</span>
										<div className="item-pic">
											<picture>
												<source srcSet="images/levels-pro.webp" media="(min-width: 768px)" />
												<img srcSet="images/levels-pro.webp" alt="" className="img-responsive" />
											</picture>
										</div>
									</div>

									<ul className="item-feats">
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>75 wallets</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>Reduced fees</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>ASAS Mode</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>SBOS Mode</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>Advantage System Access</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>Chost Router Access</span>
										</li>
										<li>
											<i>
												<img src={no} alt="" />
											</i>
											<span>API Access</span>
										</li>
										<li>
											<i>
												<img src={no} alt="" />
											</i>
											<span>Custom Batch Order Execution</span>
										</li>
										<li>
											<i>
												<img src={no} alt="" />
											</i>
											<span>Unique Trade Data inflow</span>
										</li>
									</ul>
									<div className="bottom-box">
										<button type="button">
											<span>get started</span>
											<i>
												<img src={arrow} alt="" />
											</i>
										</button>
									</div>
								</div>

								<div className="table-item enterprise swiper-slide">
									<div className="item-head">
										<div className="top-row">

										</div>
										<span className="item-name">enterprise</span>
										<div className="item-pic" ref={sequenceElem}>
											<canvas ref={canvasElem} className="js_xsNoChangeVH"></canvas>
										</div>
									</div>

									<ul className="item-feats">
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>500 wallets</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>Reduced fees</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>ASAS Mode</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>SBOS Mode</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>Advantage System Access</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>Chost Router Access</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>API Access</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>Custom Batch Order Execution</span>
										</li>
										<li className="active">
											<i>
												<img src={yes} alt="" />
											</i>
											<span>Unique Trade Data inflow</span>
										</li>
									</ul>
									<div className="bottom-box">
										<button type="button">
											<span>get started</span>
											<i>
												<img src={arrow} alt="" />
											</i>
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="bottom-infobox">
							<span>
								The Ultimate <br />
								Defi Trading Platform
							</span>
						</div>
					</div>
				</div>
				<div className="sect-bg">
					<picture>
						<source srcSet="images/levels-system-section-bg.webp" media="(min-width: 768px)" />
						<img srcSet="images/levels-system-section-bg.webp" alt="" className="img-responsive" />
					</picture>
				</div>
			</div>
		</>
	)
}
