import { useState, useRef, useEffect } from 'react'
import props from 'prop-types'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin(useGSAP, ScrollTrigger)

import './LevelsAboutSection.scss'
import { Linear } from 'gsap/gsap-core'
import yes from '@/assets/images/vector/galka-good.svg'

import arrow from '@/assets/images/vector/arrow-long.svg'

//const images = window.imagesCanvasArray
export default function LevelsAboutSection() {
	const levelsSect = useRef()


	useEffect(() => {

	}, [])

	useGSAP(() => {
		gsap.timeline({
			paused: true,
			scrollTrigger: {
				trigger: levelsSect.current,
				start: 'top bottom',
				end: () => 'bottom top',
				pin: '.sect-bg',
				scrub: true,
				invalidateOnRefresh: true,
			}
		})
		let thisTL2 = gsap.timeline({
			scrollTrigger: {
				trigger: levelsSect.current,
				start: "top bottom",
				end: () => '+=' + window.innerHeight / 2,
				scrub: true,
				invalidateOnRefresh: true,
			},
		})

		thisTL2.fromTo(
			'.bg-back',
			{ opacity:0 },
			{ opacity:1, ease: Linear.easeNone },
			0,
		)
		let thisTL3 = gsap.timeline({
			scrollTrigger: {
				trigger: levelsSect.current,
				start: "top bottom",
				end: "bottom top",
				scrub: true,
				invalidateOnRefresh: true,
			},
		})

		thisTL3.fromTo(
			'.bg-front',
			{ yPercent:0 },
			{ yPercent:-30, ease: Linear.easeNone },
			0,
		)
	}, { scope: levelsSect }) // <-- scope for selector text (optional)
	return (
		<>
			<div className="levels-about-section" ref={levelsSect}>
				<div className="sect-content">
					<div className="short-container">
						<div className="heading-box">
							<h2>
								<span>
									<b>/</b>Level system
								</span>
							</h2>
						</div>
						<div className="main-textbox">
							<p>Earn XP and level up for trading, referring users, and engaging in daily activities</p>
						</div>
						<div className="system-list">
							<div className="item">
								<div className="pic">
									<picture>
										<source srcSet="images/level-info02.webp" media="(min-width: 768px)" />
										<img srcSet="images/level-info02.webp" alt="" className="img-responsive" />
									</picture>
								</div>
								<div className="infobox">
									<span>Compete with others to rise ranks on the leaderboard for rewards</span>
								</div>
							</div>
							<div className="item">
								<div className="pic">
									<picture>
										<source srcSet="images/level-info01.webp" media="(min-width: 768px)" />
										<img srcSet="images/level-info01.webp" alt="" className="img-responsive" />
									</picture>
								</div>
								<div className="infobox">
									<p>As you level up, unlock benefits like </p>
									<ul>
										<li>
											<i>
												<img src={yes} alt="" />
											</i>
											<span>reduced trading fees,</span>
										</li>
										<li>
											<i>
												<img src={yes} alt="" />
											</i>
											<span>access to premium features</span>
										</li>
										<li>
											<i>
												<img src={yes} alt="" />
											</i>
											<span>xp multipliers</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="main-btn-box">
							<a href="#" className="launch-btn">
								<span>launch app</span>
								<i>
									<img src={arrow} alt="" />
								</i>
							</a>
						</div>
					</div>
				</div>
				<div className="sect-bg">
					<div className="bg-back">
						<picture>
							<source srcSet="images/level-info-bg-back.webp" media="(min-width: 768px)" />
							<img srcSet="images/level-info-bg-back.webp" alt="" className="img-responsive" />
						</picture>
					</div>

					<div className="bg-front">
						<picture>
							<source srcSet="images/level-info-bg.webp" media="(min-width: 768px)" />
							<img srcSet="images/level-info-bg.webp" alt="" className="img-responsive" />
						</picture>
					</div>
				</div>
			</div>
		</>
	)
}
