import { useState, useRef, useEffect } from 'react'
import props from 'prop-types'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin(useGSAP, ScrollTrigger)

import './OverviewSection.scss'
import { Linear } from 'gsap/gsap-core'
import arrowTopRight from '@/assets/images/vector/arrow-top-right.svg'
import Swiper from 'swiper';
import { Navigation, Scrollbar } from 'swiper/modules';
import { isMobile } from 'react-device-detect'
//const images = window.imagesCanvasArray
export default function OverviewSection() {
	const overviewSect = useRef()
	const swiperElem = useRef()


	useEffect(() => {
		if(isMobile){
			const swiper = new Swiper(swiperElem.current, {
				modules: [Navigation, Scrollbar],
				slidesPerView: 'auto',
				loop: false,
				scrollbar: {
					el: '.overview-section  .swiper-scrollbar',
				},

			});
		}

	}, [])

	useGSAP(() => {
		if(!isMobile){
			gsap.timeline({
				paused: true,
				scrollTrigger: {
					trigger: overviewSect.current,
					start: 'top bottom',
					end: () => 'bottom top',
					pin: '.sect-bg',
					scrub: true,
					invalidateOnRefresh: true,
				}
			})
			let thisTLOut = gsap.timeline({
				scrollTrigger: {
					trigger: overviewSect.current,
					start: "top bottom",
					end: "bottom top",
					scrub: true,
					invalidateOnRefresh: true,
				},
			})

			thisTLOut.fromTo(
				'.sect-bg',
				{ yPercent:0 },
				{ yPercent:-60, ease: Linear.easeNone },
				0,
			)
		}

	}, { scope: overviewSect }) // <-- scope for selector text (optional)
	return (
		<>
			<div className="overview-section" ref={overviewSect}>
				<div className="sect-content">
					<div className="heading-box">
						<div className="short-container">
							<div className="line-row">
								<span className="title">best tools for trading</span>
								<span className="subtitle">open and try</span>
							</div>
							<hr />
						</div>
						<div className="container">
							<h2>
								<span>
									<b>/</b>Features
								</span>
								<span>overview</span>
							</h2>
						</div>
					</div>
					<div className="container">
						<div className="swiper" ref={swiperElem}>
							<div className="over-feats-list swiper-wrapper">
								<div className="item swiper-slide">
									<div className="feat-item med">
										<div className="feat-content">
											<span className="title">Limit Orders</span>
											<p>
												Set precise limit orders to control the maximum price you are willing to pay or the minimum price you are willing to accept.
												Ensure your trades are executed at optimal prices with our advanced limit settings.
											</p>
										</div>
										<div className="bg">
											<picture>
												<source srcSet="images/features01.webp" media="(min-width: 768px)" />
												<img srcSet="images/features01.webp" alt="" className="img-responsive" />
											</picture>
										</div>
									</div>
								</div>
								<div className="item swiper-slide">
									<div className="feat-item sm">
										<div className="feat-content">
											<span className="title">Order Types</span>
											<p>Multiple order types to suit different trading strategies, including market orders, limit orders, stop orders, and SBOS (Strategic Buy Order System). Customize your orders to fit your specific trading needs.</p>
										</div>
										<div className="bg">
											<picture>
												<source srcSet="images/features04.webp" media="(min-width: 768px)" />
												<img srcSet="images/features04.webp" alt="" className="img-responsive" />
											</picture>
										</div>
									</div>
								</div>
								<div className="item swiper-slide">
									<div className="feat-item sm">
										<div className="feat-content">
											<span className="title">
												Discover & <br />
												Monitor
											</span>
											<p>Access real-time and historical token price charts to stay informed about market trends and make data-driven decisions.</p>
										</div>
										<div className="bg">
											<picture>
												<source srcSet="images/features02.webp" media="(min-width: 768px)" />
												<img srcSet="images/features02.webp" alt="" className="img-responsive" />
											</picture>
										</div>
									</div>
								</div>
								<div className="item swiper-slide">
									<div className="feat-item">
										<div className="feat-content">
											<span className="title">Snipe Orders</span>
											<p>Specialized order types designed for sniping opportunities in the market. Features include ASAS (Auto Search Auto Snipe) for automated execution, ensuring timely and efficient market entries.
											</p>
										</div>
										<div className="bg">
											<picture>
												<source srcSet="images/features05.webp" media="(min-width: 768px)" />
												<img srcSet="images/features05-xs.webp" alt="" className="img-responsive" />
											</picture>
										</div>
									</div>
								</div>
								<div className="item swiper-slide">
									<div className="feat-item">
										<div className="feat-content">
											<span className="title">Position Management</span>
											<p>
												Tools to manage and monitor your open positions. Includes features for setting stop-loss and take-profit levels, tracking
												performance, and making adjustments to optimize your portfolio.
											</p>
										</div>
										<div className="bg">
											<picture>
												<source srcSet="images/features03.webp" media="(min-width: 768px)" />
												<img srcSet="images/features03-xs.webp" alt="" className="img-responsive" />
											</picture>
										</div>
									</div>
								</div>
								<div className="item swiper-slide">
									<div className="feat-item med">
										<div className="feat-content">
											<span className="title">
												Performance <br />
												Analytics
											</span>
											<p>In-depth analytics to track your trading performance, market trends, and portfolio metrics. Use data-driven insights to refine your trading strategies.</p>
										</div>
										<div className="bg">
											<picture>
												<source srcSet="images/features06.webp" media="(min-width: 768px)" />
												<img srcSet="images/features06.webp" alt="" className="img-responsive" />
											</picture>
										</div>
									</div>
								</div>
							</div>
							<div className="swiper-scrollbar"></div>
						</div>
					</div>
				</div>
				<div className="sect-bg">
					<picture>
						<source srcSet="images/overview-section-bg.webp" media="(min-width: 768px)" />
						<img srcSet="images/overview-section-bg-xs.webp" alt="" className="img-responsive" />
					</picture>
				</div>
			</div>
		</>
	)
}
