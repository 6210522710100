import { useState, useEffect, useRef } from 'react'

import './Preloader.scss'
import logo from '/images/logo.svg'
import preloaderPic from '/images/preloader-pic.webp'
import preloaderFill from '/images/preloader-fill.webp'

export default function Preloader({ isCanLoadSections }) {
	const [PercentLoaded, setPercentLoaded] = useState(false)
	const loaderElem = useRef()
	useEffect(() => {
		console.log(`mounted preloader`)
		const sequencesArray = [
			{
				id:'topScreen',
				framesPath: 'images/sequences/top-screen/',
				startFrame: 1,
				endFrame: 140,
				supportedExtension: '.webp'
			},
			{
				id:'showcase',
				framesPath: 'images/sequences/showcase/',
				startFrame: 1,
				endFrame: 100,
				supportedExtension: '.webp'
			}
		]
		let percentageLoaded = 0
		let imagesLoadedCount = 0
		let totalImagesCount = 0
		sequencesArray.forEach((sequenceItem) => {
			totalImagesCount += sequenceItem.endFrame
		})
		sequencesArray.forEach((sequenceItem) => {
			let imagesArray = []
			for (let i = sequenceItem.startFrame; i <= sequenceItem.endFrame; i++) {
				const img = new Image()
				img.src = sequenceItem.framesPath + i.toString().padStart(4, "0") + sequenceItem.supportedExtension
				imagesArray.push(img)

			}
			if(sequenceItem.id === 'topScreen') {
				window.imagesArrayTopScreen = imagesArray
			}

			if(sequenceItem.id === 'showcase') {
				window.imagesArrayShowcase = imagesArray
			}
			imagesArray.forEach( img => {
				img.onload = function() {
					++imagesLoadedCount
					setPercentLoaded( parseInt((100 / totalImagesCount) * imagesLoadedCount) )
					if (totalImagesCount === imagesLoadedCount) {
						console.log(`all images is loaded `)
						loaderElem.current.classList.add('loaded')
						isCanLoadSections(1)
					}
				}
			});
		})



	}, [])

	return (
		<>
			<div className="preloader" ref={loaderElem}>
				<div className="preloader-holder">

					<div className="center-box">
						<div className="picbox">
							<img src={preloaderPic} alt="" className="biglogo" />
							<div className="fill" style={{ height: PercentLoaded + '%' }}>
								<img src={preloaderFill} alt="" className="filllogo" />
							</div>
						</div>

					</div>

				</div>
			</div>
		</>
	)
}
