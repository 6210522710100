import { useState, useReducer, useRef } from 'react'
import './assets/fonts/fonts.scss'
import './App.scss'
import { BrowserView, MobileView,  isMobile, isDesktop } from 'react-device-detect';
import { gsap } from 'gsap'
import { ScrollSmoother } from 'gsap/ScrollSmoother.js'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { useGSAP } from '@gsap/react'
gsap.registerPlugin(useGSAP, ScrollTrigger, ScrollSmoother)

import Preloader from '@/components/preloader/Preloader.jsx'
import Header from '@/components/page-header/Header.jsx'
import Footer from '@/components/page-footer/Footer.jsx'
import FirstScreenSection from '@/components/first-screen-section/FirstScreenSection.jsx'
import FeaturesSection from '@/components/features-section/FeaturesSection.jsx'
import ShowcaseSection from '@/components/showcase-section/ShowcaseSection.jsx'
import OverviewSection from '@/components/overview-section/OverviewSection.jsx'
import LevelsSection from '@/components/levels-system-section/LevelsSection.jsx'
import ReferralsSection from '@/components/referrals-section/ReferralsSection.jsx'
import LevelsAboutSection from '@/components/levels-about-section/LevelsAboutSection.jsx'

window.imagesArrayTopScreen = []
window.imagesArrayFeat = []
window.imagesArrayShowcase = []


function App() {
	const bodyPageElem = useRef()
	const main = useRef()
	const smoother = useRef()
	const [isLoadedPreloader, setisLoadedPreloader] = useState(false)
	const [, forceUpdate] = useReducer((x) => x + 1, 0)
	const handleLoadedPreloader = (count) => {
		//console.log(count)
		setisLoadedPreloader(count)
		bodyPageElem.current.classList.add('loaded')
		// console.log(isCanLoaded)
	}
	if(!isMobile){
		useGSAP(
			() => {
				// create the smooth scroller FIRST!
				smoother.current = ScrollSmoother.create({
					smooth: 0.8, // how long (in seconds) it takes to "catch up" to the native scroll position
					effects: true, // looks for data-speed and data-lag attributes on elements
					smoothTouch: false,
					speed: 1.5
				})
			},
			{ scope: main }
		)
	}

	return (
		<>
			<div className="page-main-wrap">
				<div className="body-page" ref={bodyPageElem}>
					<Preloader isCanLoadSections={handleLoadedPreloader} />
					<Header />
					<div className="main-page-holder" id="smooth-wrapper" ref={main}>
						<div className="all-page-wrapper" id="smooth-content">
							<div className="all-page-holder">
								{isLoadedPreloader && (
									<>
										<FirstScreenSection />
										<FeaturesSection />
										<ShowcaseSection />
										<OverviewSection />
										<LevelsAboutSection />
										<ReferralsSection/>
										<LevelsSection />



									</>
								)}
								<Footer />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default App
