import { useState, useRef, useEffect } from 'react'
import props from 'prop-types'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { useGSAP } from '@gsap/react'

gsap.registerPlugin(useGSAP, ScrollTrigger)

import './ReferralsSection.scss'
import { Linear } from 'gsap/gsap-core'
import yes from '@/assets/images/vector/galka-good.svg'
import no from '@/assets/images/vector/galka-bad.svg'
import arrow from '@/assets/images/vector/arrow-connect.svg'

//const images = window.imagesCanvasArray
export default function ReferralsSection() {
	const referralsSect = useRef()

	useEffect(() => {

	}, [])

	useGSAP(() => {
		let thisTL = gsap.timeline({
			paused: true,
			scrollTrigger: {
				trigger: '.sect-bg',
				start: 'top bottom',
				end: () => 'bottom top',
				scrub: true,
				invalidateOnRefresh: true,

			}
		})
		thisTL.fromTo(
			'.sect-bg picture',
			{ y:-100},
			{  y: 200, ease: Linear.easeNone },
			0
		)
	}, { scope: referralsSect }) // <-- scope for selector text (optional)
	return (
		<>
			<div className="referrals-section" ref={referralsSect}>
				<div className="container sect-main">
					<div className="sect-content">
						<div className="sect-bg">
							<picture>
								<source srcSet="images/ref-sect-bg.webp" media="(min-width: 768px)" />
								<img srcSet="images/ref-sect-bg.webp" alt="" className="img-responsive" />
							</picture>
						</div>
						<div className="heading-box">
							<h2>
								<span>
									<b>/</b>REFERRALS
								</span>
							</h2>
						</div>
						<div className="short-container">
							<div className="line-row">
								<span className="title">best tools for trading</span>
								<span className="subtitle">open and try</span>
							</div>
							<hr />
							<div className="heading-right">
								<span className="right-heading">
									<span>
										<b>/</b>Earn Rewards by
									</span>
									<span>Referring users</span>
								</span>
							</div>
							<div className="members-list">
								<div className="item">
									<div className="pic">
										<picture>
											<source srcSet="images/ref01.webp" media="(min-width: 768px)" />
											<img srcSet="images/ref01.webp" alt="" className="img-responsive" />
										</picture>
									</div>
									<span>Share your unique referral link to earn xp</span>
								</div>
								<div className="item">
									<div className="pic">
										<picture>
											<source srcSet="images/ref02.webp" media="(min-width: 768px)" />
											<img srcSet="images/ref02.webp" alt="" className="img-responsive" />
										</picture>
									</div>
									<span>Earn eth, sol, or base when referred users trade using your referral</span>
								</div>
								<div className="item">
									<div className="pic">
										<picture>
											<source srcSet="images/ref03.webp" media="(min-width: 768px)" />
											<img srcSet="images/ref03.webp" alt="" className="img-responsive" />
										</picture>
									</div>
									<span>Monitor your referral metrics and rewards through your dashboard.</span>
								</div>
							</div>
						</div>
					</div>

				</div>


			</div>
		</>
	)
}
