import { useState, useRef, useEffect, useReducer } from 'react'
import props from 'prop-types'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger.js'
import { useGSAP } from '@gsap/react'
import { BrowserView, MobileView, isMobile, isDesktop } from 'react-device-detect'
gsap.registerPlugin(useGSAP, ScrollTrigger)

import './ShowcaseSection.scss'
import { Linear } from 'gsap/gsap-core'
import arrow from '@/assets/images/vector/arrow-long.svg'
import arrowTopRight from '@/assets/images/vector/arrow-top-right.svg'



//const images = window.imagesCanvasArray
export default function ShowcaseSection () {
	const showcaseSect = useRef()
	const canvasElem = useRef()
	const sequenceFrames = {
		frame: 1,
	}
	let currentImagesLoaded = 0
	const startFrame = 1
	const endFrame = 100
	const frameCount = endFrame - startFrame
	let imagesArray = window.imagesArrayShowcase
	let canvas
	let context
	function render() {
		if(context){
			context.clearRect(0, 0, canvas.width, canvas.height)
			context.drawImage(imagesArray[sequenceFrames.frame], 0, 0)
		}

	}
	useEffect(() => {
		canvas = canvasElem.current
		context = canvasElem.current.getContext("2d")
		canvas.width = 1920
		canvas.height = 1280

	}, []);




	useGSAP(
		() => {
			if(isMobile){
				gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.sect-fix-wrap',
						start: 'top bottom',
						end: () => 'bottom bottom',
						pin: '.section-fixed-box',
						scrub: true,
						invalidateOnRefresh: true,
					}
				})
				let thisTLRender = gsap.timeline({
					scrollTrigger: {
						trigger: '.sect-fix-wrap',
						start: "top bottom",
						end: "bottom top",
						scrub: true,
						invalidateOnRefresh: true,
						onUpdate: render,

					},
				})
				thisTLRender.fromTo(
					sequenceFrames,
					{ frame: () => 1 },
					{ frame: () => frameCount, snap: "frame", ease: Linear.easeNone },
					0,
				)

			}
			else{
				gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: '.sect-fix-wrap',
						start: 'top bottom',
						end: () => 'bottom top',
						pin: '.section-fixed-box',
						scrub: true,
						invalidateOnRefresh: true,
					}
				})

				let thisTL = gsap.timeline({
					scrollTrigger: {
						trigger: '.sect-fix-wrap',
						start: 'top bottom',
						end: () => 'bottom top',
						scrub: true,
						invalidateOnRefresh: true,

					}
				})

				thisTL.fromTo(
					'.twoquads-box',
					{ y:50},
					{ y:-200, ease: Linear.easeNone },
					0
				)

				let thisTLRender = gsap.timeline({
					scrollTrigger: {
						trigger: '.sect-fix-wrap',
						start: "top top",
						end: "bottom bottom",
						scrub: true,
						invalidateOnRefresh: true,
						onUpdate: render,

					},
				})
				thisTLRender.fromTo(
					sequenceFrames,
					{ frame: () => 1 },
					{ frame: () => frameCount, snap: "frame", ease: Linear.easeNone },
					0,
				)

				let thisTLOut = gsap.timeline({
					scrollTrigger: {
						trigger: '.sect-fix-wrap',
						start: "bottom bottom",
						end: "bottom top",
						scrub: true,
						invalidateOnRefresh: true,
						onUpdate: render,

					},
				})
				thisTLOut.fromTo(
					'.sect-bg',
					{opacity:1 },
					{opacity:0, ease: Linear.easeNone },
					0,
				)
				thisTLOut.fromTo(
					'.section-content',
					{opacity:1, yPercent:0 },
					{opacity:0, yPercent:-30, ease: Linear.easeNone },
					0,
				)
			}

			let thisTLQuad = gsap.timeline({
				scrollTrigger: {
					trigger: '.sect-fix-wrap',
					start: 'center center',
					end: () => 'bottom top',
					scrub: false,
					invalidateOnRefresh: true,
					toggleActions: "play none none reverse",

				}
			})

			thisTLQuad.fromTo(
				'.desk-quad .desk-bottom',
				{ opacity:0},
				{ opacity:1, ease: Linear.easeNone, duration:0.3 },
				0
			)
			thisTLQuad.fromTo(
				'.desk-quad .desk-bottom-mob',
				{ opacity:1},
				{ opacity:0, ease: Linear.easeNone, duration:0.3 },
				0
			)






		},
		{ scope: showcaseSect }
	) // <-- scope for selector text (optional)
	return (
		<>
			<div className="showcase-section" ref={showcaseSect}>
				<div className="sect-fix-wrap">
					<div className="section-fixed-box">
						<div className="section-content">
							<div className="section-top-content ">

								<div className="container">
									<h2>
										<span>
											<b>/</b>ACCESS{' '}
										</span>
										<span>ANYWHERE</span>
									</h2>
								</div>
							</div>
							<div className="section-bottom-content">
								<div className="short-container">
									<div className="twoquads-box">
										<div className="desk-quad">
											<div className="quad-holder">
												<div className="desk-bottom">
													<span className="name">desktop</span>
												</div>
												<div className="desk-bottom-mob">
													<span className="name">MOBile</span>
												</div>
											</div>
										</div>
										<div className="mob-quad">
											<i>
												<img src={arrowTopRight} alt="" className="img-responsive" />
											</i>
											<div className="launch-top">

											</div>
											<div className="desk-bottom">
												<span className="name">
													launch <br />
													app
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="sect-bg">
							<canvas ref={canvasElem} className="js_xsNoChangeVH"></canvas>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
